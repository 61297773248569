import { Controller } from '@hotwired/stimulus'
import { type HTMLEvent } from '../types/html_event'

// Connects to data-controller="sensor-tabs"
export default class extends Controller {
  declare sensors: NodeListOf<HTMLElement>
  declare tabs: NodeListOf<HTMLElement>

  // TODO: Refactor so that Tailwind is not used.
  ACTIVE_CLASSES: string[] = ['border-r', 'border-r-[#ddd]', 'border-t', 'border-t-[#ddd]', 'border-l', 'border-l-[#ddd]', '!border-b-0', 'font-semibold', '!cursor-default', 'z-20']

  connect (): void {
    this.sensors = document.querySelectorAll<HTMLElement>('.eventForm-sensor')
    this.tabs = document.querySelectorAll<HTMLElement>('.eventForm-sensorTab')
    // Activate the first tab with an error or last tab
    if (this.tabs.length > 0) {
      const firstTabIndexWithError = Array.from(this.tabs).findIndex((tab) => tab.classList.contains('eventForm-sensorTab-withError'))
      const lastTab = this.tabs[firstTabIndexWithError] || this.tabs[this.tabs.length - 1]
      const lastSensor = this.sensors[firstTabIndexWithError] || this.sensors[this.sensors.length - 1]
      this.openSensor(lastSensor, lastTab)
    }
  }

  click (e: HTMLEvent): void {
    if (e.target !== e.currentTarget) return

    const tab = e.currentTarget
    const { targetid } = tab.dataset
    const target = document.getElementById(targetid ?? '')
    this.openSensor(target, tab)
  }

  openSensor (sensor: HTMLElement | null, tab: HTMLElement | null): void {
    if (sensor === null || tab === null) return

    this.sensors.forEach((sensor) => {
      sensor.classList.remove('eventForm-activeSensor')
      sensor.classList.add('eventForm-inactiveSensor')
    })

    this.tabs.forEach((tab) => {
      this.deactivate(tab)
    })

    sensor.classList.remove('eventForm-inactiveSensor')
    sensor.classList.add('eventForm-activeSensor')
    this.activate(tab)
  }

  activate (tab: HTMLElement): void {
    tab.classList.add(...this.ACTIVE_CLASSES)
  }

  deactivate (tab: HTMLElement): void {
    tab.classList.remove(...this.ACTIVE_CLASSES)
  }
}
