import DialogController from './dialog_controller'

// Connects to data-controller="events-search"
export default class extends DialogController {
  connect (): void {
    this.dialogTarget.addEventListener('close', () => {
      document.body.style.overflow = 'auto'
    })
  }

  openDialog (): void {
    document.body.style.overflow = 'hidden'
    super.openDialog()
  }
}
